.tpC {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tpC img {
  height: 140px;
}

.tpC h6 {
  color: black;
  font-size: 1rem;
}

.fTP {
  margin: 15px 80px;
  text-align: center;
}

.sTP {
  margin: 15px 80px;
  text-align: center;
}

.tTP {
  margin: 15px 80px;
  text-align: center;
}

@media (max-width: 800px) {
  .tpC img {
    height: 120px;
  }
}

@media (max-width: 600px) {
  .tpC img {
    height: 150px;
  }

  .tpC {
    flex-direction: column;
  }
}
