.MPTextContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.TextControl {
  text-align: center;

  width: 60%;
}

.TextControl h4 {
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}

.TextControl p {
  font-size: 18px;
  line-height: 22px;
  margin-top: 15px;
}

@media (max-width: 600px) {
  .TextControl {
    width: 80%;
  }

  .TextControl h4 {
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
  }
}
