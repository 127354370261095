.sideMenuButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 300;
}

.sideMenuButton:focus {
  outline: none;
}

.sideButtonLine {
  width: 25px;
  height: 2px;
  background: white;
}
