.mainContainerA {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mediaWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("img/bgTruck.png") no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
  text-align: center;
}

.mediaWrap h2 {
  color: white;
}

.mediaControlA {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainContainerA h2 {
  font-size: 2rem;
}

.aBoxA video {
  border: 2px solid white;
  width: 1000px;
  height: 500px;
}

.mediaControlA img {
  height: 300px;
  width: 300px;
  margin: 0 50px;
}

.aSBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.aSBlockT {
  width: 40%;
}

.aSBlockT h3 {
  font-size: 2rem;
}

.aSBlockT p {
  font-size: 1.4rem;
}

.aTBlock {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.aTBlockB {
  display: flex;
  flex-direction: row;
  margin: 30px 0;
  width: 40%;
  text-align: left;
}

.aTBlockText {
  margin-left: 50px;
}

.aTBlockText h3 {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.aTBlockB img {
  height: 175px;
}

@media (max-width: 1500px) {
  .aTBlockB {
    width: 60%;
  }
  .mediaWrap {
    padding: 50px 0px;
  }
}

@media (max-width: 800px) {
  .aSBlockT {
    width: 60%;
  }

  .mediaControlA {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .aTBlockText {
    margin-left: 25px;
  }

  .aTBlockB {
    margin: 15px 0;
    width: 80%;
  }

  .mediaControlA img {
    margin: 0 25px;
    height: 150px;
    width: 150px;
    margin-top: 40px;
  }

  .aBoxA {
    margin-top: 40px;
  }

  .aBoxA video {
    width: 700px;
    height: 500px;
  }

  .mainContainerA h2 {
    margin: 0 20px;
  }
}

@media (max-width: 600px) {
  .aSBlockT {
    width: 85%;
  }

  .mediaWrap {
    height: 50vh;
  }

  .aTBlockB {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    width: 85%;
    text-align: center;
  }

  .aTBlockText {
    margin-left: 0px;
  }

  .mediaControlA img {
    margin: 0 0px;
    height: 0;
    width: 0;
  }

  .aBoxA {
    width: 250px;
    height: 140px;
  }

  .aBoxA video {
    width: 250px;
    height: 140px;
  }

  .mainContainerA h2 {
    margin: 0 20px;
  }
}
