.MPLcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MPLul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.MPLul li {
  width: 300px;
  height: 300px;
  margin: 1rem 1rem;
  transition: all 1s;
}

.MPLcontainers {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.MPLul a {
  text-decoration: none;
  color: white;
  -webkit-text-stroke: 0.45px black;
  text-align: center;
}

.MPLimg {
  text-align: center;
}

.MPLul li img {
  margin-bottom: 10px;
}

.MPLul li:hover {
  transform: scale(1.1);
  box-shadow: 10px 10px #888888;
}

h5 {
  font-size: 2rem;
  font-weight: 800;
  margin: 0 0;
}

#MPLfLi {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/commercial.jpg");
  background-size: 100% 100%;
}

#MPLsLi {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/local.jpg");

  background-size: 100% 100%;
}

#MPLtLi {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/packing.jpg");
  background-size: 100% 100%;
}

#MPLfrLi {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/storage.jpg");
  background-size: 100% 100%;
}

#MPLfthLi {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/delivery.jpg");
  background-size: 100% 100%;
}

#MPshLi {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/urgent.jpeg");
  background-size: 100% 100%;
}

@media (max-width: 600px) {
  .MPLul li {
    width: 200px;
    height: 200px;
    margin: 1rem 0rem;
  }

  .MPLul {
    padding-left: 0px;
  }

  h5 {
    font-size: 1.5rem;
    font-weight: 100;
  }
}
