.linksContainer {
  display: flex;
  width: 100%;

  align-items: flex-start;
  justify-content: center;
}

.ulLinks {
  display: flex;
  list-style-type: none;
}

.ulLinks li img {
  height: 35px;
  padding: 0 4px;
  transition: height 1s;
}

.ulLinks li {
  padding: 0 10px;
}

.ulLinks li a {
  color: black;
  text-decoration: none;
  font-size: 1.3rem;
  transition: font-size 1s;
}

.ulLinks li a:hover {
  color: #ee5a5a;
  text-decoration-line: underline;
}

.ulLinks li img:hover {
  opacity: 0.85;
}

@media (max-width: 600px) {
  .linksContainer {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;
  }

  .ulLinks li a {
    font-size: 1rem;
  }

  .ulLinks {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .ulLinks li img {
    padding-top: 0px;
  }
}
