form {
  color: white;
}

form input {
  border-radius: 6px;
  padding: 0.3rem;
  width: 15rem;
  height: 1.2rem;
  text-align: left;
  margin-bottom: 1.2rem;
}

form label {
  font-size: 1.5rem;
}

.location {
  background: #fff url("location.png") 0px center no-repeat;
}

.dateS {
  background: #fff url("data.png") 0px center no-repeat;
  text-align: left;
}

form button {
  padding: 1rem 4.3rem;
  background-color: #ffb174;
  border: none;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
}
