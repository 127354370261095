.careersWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.careersContainer {
  width: 100%;
  background: #ee5a5a;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 150px;
}

.careersContainer div {
  font-size: 1.6rem;
}

.careersMainWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.careersMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.careersText {
  width: 30%;
  margin-right: 20px;
}

.careersText img {
  width: 100%;
}

.careersForm {
  margin-left: 20px;

  width: 30%;
}

.careersForm form label {
  color: black;
}

.careersForm form input {
  border-radius: 0px;
  width: 80%;
}

@media (max-width: 800px) {
  .careersText {
    width: 50%;
    margin-right: 20px;
  }

  .careersForm {
    margin-left: 20px;

    width: 50%;
  }
}

@media (max-width: 600px) {
  .careersContainer div {
    font-size: 1.4rem;
  }

  .careersMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }

  .careersText {
    width: 80%;
    margin-right: 0px;
  }

  .careersForm {
    margin-left: 0px;

    width: 80%;
  }
}
