.faqControl {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 50%;
}

test {
  width: 100%;
  font-size: 1.5rem;

  margin: 10px 0px;
  background-color: #ee5a5a;
  cursor: pointer;
  padding: 5px 5px;
}

.faqBox {
  font-size: 1.2rem;
  margin-top: 5px;
  border: 1px solid #000;
  background-color: #ffb174;
  cursor: default;
}

@media (max-width: 600px) {
  .faqControl {
    width: 80%;
  }
}
