.BWcontainer1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, #ffb174 0%); */
}

.BWcontainer2 {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.BWcontainer {
  background: url("bg2.png") center top no-repeat;
}

.BWhpWrap {
  width: 100%;
  display: flex;
  background: #ffb174;
}

.BWhpcontrol {
  width: 40%;
  margin-left: 20%;
  color: white;
  /* -webkit-text-stroke: 1px black; */
}

.BWhpcontrol h5 {
  font-size: 2rem;
  margin: 0 0;
  margin-top: 50px;
}

.BWhpcontrol h6 {
  font-size: 1.5rem;
  background: url("bg_footer.png") left top no-repeat;
  padding-left: 94px;
  margin: 0 0;
}

.BWhpcontrol p {
  font-size: 1.3rem;
  color: black;
}

@media (min-width: 1700px) {
  .BWcontainer2 {
    width: 70%;
  }
  #truckIMG {
    width: 800px;
    height: 500px;
    margin-left: 50px;
  }

  .BWimages {
    margin-top: 50px;
  }
}

@media (max-width: 1700px) {
  .BWcontainer2 {
    width: 80%;
  }

  #truckIMG {
    width: 800px;
    height: 500px;
    margin-left: 50px;
  }

  .BWimages {
    margin-top: 50px;
  }
}

@media (max-width: 1600px) {
  .BWcontainer2 {
    width: 85%;
  }

  #truckIMG {
    width: 800px;
    height: 500px;
    margin-left: 20px;
  }

  .BWimages {
    margin-top: 50px;
  }
}

@media (max-width: 1500px) {
  .BWcontainer2 {
    width: 70%;
  }

  #truckIMG {
    width: 650px;
    height: 400px;
    margin-left: 20px;
  }

  .BWimages {
    margin-top: 80px;
  }
}

@media (max-width: 1400px) {
  .BWcontainer2 {
    width: 70%;
  }

  #truckIMG {
    width: 550px;
    height: 350px;
    margin-left: 50px;
  }

  .BWimages {
    margin-top: 150px;
  }
}

@media (max-width: 1300px) {
  .BWcontainer2 {
    width: 70%;
  }

  #truckIMG {
    width: 500px;
    height: 350px;
    margin-left: 50px;
  }

  .BWimages {
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .BWhpcontrol {
    text-align: center;
    margin: 0;
    width: 90%;
  }

  .BWimages {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .BWhpcontrol h6 {
    background: url("bg_footer.png") top no-repeat;
    background-position: 110px;
    padding-left: 0px;
  }

  .BWhpWrap {
    justify-content: center;
  }

  .BWcontainer2 {
    flex-direction: column;
    justify-content: flex-end;
    height: 420px;
    flex-wrap: nowrap;
  }

  #truckIMG {
    display: flex;
    height: 350px;
    width: 500px;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: -30px;
  }
}

@media (max-width: 600px) {
  .BWhpcontrol {
    text-align: center;
    margin: 0;
    width: 90%;
  }

  .BWhpcontrol h6 {
    background: url("bg_footer.png") top no-repeat;

    padding-top: 15px;
  }

  .BWcontainer2 {
    flex-direction: column;
    height: 230px;
    flex-wrap: nowrap;
    height: 100%;
  }

  .BWimages img {
    height: 70px;
  }

  .BWimages {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  #truckIMG {
    display: flex;
    height: 250px;
    width: 300px;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
