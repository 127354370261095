.commentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.commentIntro {
  background: #ee5a5a linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33vh;
}

.commentIntroText {
  color: white;
  width: 30%;
  line-height: 42px;
}

.commentIntroText h2 {
  font-size: 3.8rem;
  margin-bottom: 0px;
}

.commentIntroText p {
  font-size: 1.2rem;
  text-align: right;
  margin-top: 0px;
}

.formWrapControl {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formTextControl {
  width: 50%;
  text-align: center;
}

.formTextControl h3 {
  font-size: 2rem;
  font-weight: 600;
}

.formController {
  width: 20%;
  margin-bottom: 20px;
}

.formController h3 {
  font-size: 1.7rem;
}

.formTextControl p {
  font-size: 1.3rem;
}

.formController form {
  color: black;
  text-align: left;
}

.formController form input {
  text-align: left;
  border-radius: 0;
  width: 100%;
}

.cBf {
  text-align: center;
}

@media (max-width: 1600px) {
  .commentIntroText {
    width: 45%;
  }
}

@media (max-width: 800px) {
  .commentIntro {
    height: 300px;
  }

  .commentIntroText {
    width: 65%;
  }

  .formTextControl {
    width: 70%;
  }

  .formController {
    width: 45%;
  }
}

@media (max-width: 600px) {
  .commentIntroText {
    line-height: 48px;
    text-align: center;
  }

  .formTextControl {
    width: 80%;
  }

  .formController {
    width: 70%;
  }
}
