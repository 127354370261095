.navBar_header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 70px;
  z-index: 3;
}

.navBar_navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
}

.spacer {
  flex: 1;
}

.phoneDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 2px solid #ffb174;
  border-radius: 1px;
  padding: 5px 5px;
  transition: all 1s;
  text-decoration: none;
}

.phoneDiv p {
}

.phoneDiv p a {
  color: white;
  font-weight: 200;
}

.phoneDiv p {
  margin: 0 0;
  font-weight: 600;
}

.navBar_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navBar_items li {
  padding: 0 0.5rem;
}

.navBar_items a {
  text-decoration: none;
}

.navBar_Logo {
  margin-left: 1rem;
}

#firstA {
  padding: 10px 10px;
  transition: ease 0.3s;
  border-radius: 0px;
  border: 1px solid #fff;
  color: #fff;
  width: 200px;
  position: relative;
  z-index: 80;
}
#firstA:after {
  transition: ease 0.3s;
  content: "";
  width: 100%;
  position: absolute;
  height: 0px;
  background-color: #ffb174;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
}
#firstA:hover {
  color: #fff;
}
#firstA:hover:after {
  height: 100%;
}

#secondA {
  overflow: auto;
  padding: 10px 10px;
  transition: ease 0.3s;
  border-radius: 0px;

  border: 1px solid #ffb174;
  color: #fff;
  width: 200px;
  position: relative;
  z-index: 80;
}

#secondA:after {
  transition: ease 0.3s;
  content: "";
  width: 100%;
  position: absolute;
  height: 0px;
  background-color: #fff !important;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
}
#secondA:hover {
  color: #ffb174;
}
#secondA:hover:after {
  height: 100%;
}

@media (max-width: 800px) {
  #firstA {
    font-size: 0.6rem;
  }

  #secondA {
    font-size: 0.6rem;
  }

  #phoneDiv p {
    font-size: 0.7rem;
  }

  .phoneDiv img {
    height: 25px;
  }
}

@media (max-width: 600px) {
  .navBar_items {
    display: none;
  }

  .phoneDiv {
    display: none;
  }
}

.phoneDiv:hover {
  text-decoration-color: #ffb174;
  color: #ffb174;
}

.phoneDiv a:hover {
  color: #ffb174;
}
