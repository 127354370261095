.locationController {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.AddressC {
  height: 100%;
  width: 50%;
  font-size: 1.2rem;
}

.mapLocation {
  width: 50%;
  height: 500px;
  margin-bottom: 10px;
}

.locationsTextControl {
  width: 40%;

  text-align: center;
}

.locationsTextControl h3 {
  font-size: 2rem;
}

.locationsTextControl p {
  font-size: 1.4rem;
}

.mapStyle {
  width: "50%";
  height: "500px";
}

@media (max-width: 600px) {
  .AddressC {
    height: 100%;
    width: 60%;
  }

  .mapLocation {
    width: 70%;
    height: 500px;
  }

  .locationsTextControl {
    width: 80%;
    text-align: center;
  }

  .mapStyle {
    width: 70%;
  }
}
