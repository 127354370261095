.slide-container {
  width: 50%;
  margin: auto;
}

h3 {
  text-align: center;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 500px;
}

.vanImg {
  background: url("img/vancouver.jpg");
}

.burImg {
  background: url("img/burnaby.jpg");
}

.coqImg {
  background: url("img/coquitlam.jpg");
}

.nwImg {
  background: url("img/newwestminster.jpg");
}

.each-slide span {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.3);
  color: black;
  text-align: center;
  font-size: 3.5rem;
  align-items: center;
}

.each-slide a {
  color: white;
  text-decoration: none;
  margin-top: 10px;
  font-size: 1.5rem;
  width: 150px;
  background-color: #ffb174;
  border: #ffb174;
  border-radius: 5px;
  padding: 6px 8px;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.lButton {
  border-radius: 0px !important;
}

@media (max-width: 600px) {
  .each-slide a {
    margin-top: 10px;
    font-size: 1.3rem;
    width: 100px;
    background-color: #ffb174;
    border: #ffb174;
  }

  .each-slide span {
    font-size: 2.2rem;
  }
}
