.formController1 {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.formController2 {
  margin-left: 50px;
  width: 25%;
}

.formController2 h3 {
  font-size: 1.7rem;
}

.formTextControl2 p {
  font-size: 1.3rem;
}

.formController2 form {
  color: black;
  text-align: left;
}

.form {
  color: black;
  text-align: left;
}

.form input {
  text-align: left;
  border-radius: 0;
  width: 95%;
}

.formController2 form input {
  text-align: left;
  border-radius: 0;
  width: 95%;
}

.contactAddress {
  width: 20%;
}

@media (max-width: 800px) {
  .formController2 {
    margin-left: 25px;
    width: 50%;
  }

  .contactAddress {
    width: 35%;
  }
}

@media (max-width: 600px) {
  .formController1 {
    flex-direction: column;
    align-items: center;
  }

  .formController2 {
    margin-left: 0px;
    width: 90%;
  }

  .contactAddress {
    width: 90%;
    margin-bottom: 10px;
  }
}
