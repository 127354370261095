.Fcontroler {
  display: flex;
  flex-direction: row;
  justify-content: center;

  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.Fbox1 {
  width: 300px;
  height: 250px;
}

.Fbox1 ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0px;
}

.Fbox1 ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.Fbox1 ul li a:hover {
  text-decoration: underline;
}

.Fbox2 {
  width: 300px;
  height: 250px;
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Fbox2 ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  padding-left: 0px;
}

.fBox2Img {
  margin-top: 50px;
}

.fBox2Img img {
  height: 100px;
}

.Fbox2 ul li a {
  color: white;
  text-decoration: none;
  padding: 0px 10px;
  font-size: 1rem;
}

.Fbox2 ul li a:hover {
  text-decoration: underline;
}

.Fbox3 {
  width: 300px;
  height: 250px;
}

.Fbox3 ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  margin-left: 50px;
  padding-left: 0px;
}

.Fbox3 ul li {
  color: white;
  font-size: 1rem;
}

.Fbox3 ul li p {
  margin: 0px 0px;
}

#Fp {
  margin-top: 40px;
}

@media (max-width: 600px) {
  .Fbox1 {
    height: 145px;
  }

  .Fbox1 ul {
    align-items: center;
  }

  .Fbox2 {
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Fbox2 ul {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .Fbox2 ul li {
    padding-top: 0px;
  }

  .fBox2Img {
    margin-top: 0px;
  }

  .fBox2Img img {
    height: 50px;
  }

  .Fbox3 {
    height: 180px;
    padding-top: 0px;
  }

  .Fbox3 ul {
    align-items: center;
    text-align: center;
    margin-left: 0px;
  }

  .Fcontroler {
    flex-direction: column;
    align-items: center;
  }
}
