@import url("https://fonts.googleapis.com/css?family=Lato|Open+Sans&display=swap");

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

p,
span,
a {
  font-family: sans-serif, "Lato";
}
