.RQcontainer {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("bg.png");
  width: 100%;
  align-items: flex-end;
  display: flex;
  justify-content: center;
}

.RQcontainer img {
  height: 300px;
}

.RQtopPage {
  display: flex;
  max-width: 670px;
  height: 100%;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.RQtopPage h1 {
  color: black;
  font-size: 55px;
  text-align: center;
}

.RQtopPage p {
  color: black;
  font-size: 22px;
  text-align: center;
}

.RQform {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RQFormControl {
  width: 20%;
}

.RQFormControl form input {
  width: 100%;
  text-align: left;
}

.RQFormControl form {
  text-align: left;
}

.RQform form label {
  color: black;
  padding-right: 10px;
  text-align: left;
}

.RQform h2 {
  color: black;
  text-align: center;
  font-size: 1.8rem;
}

.addInfoC {
  height: 60px;
}

.RQform button {
  text-align: center;
}

#secondA {
  font-weight: 300;
}

@media (max-width: 800px) {
  .RQcontainer {
    height: 300px;
    align-items: flex-end;
  }

  .RQcontainer img {
    height: 200px;
  }

  .RQFormControl {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .RQcontainer {
    height: 200px;
    align-items: flex-end;
  }

  .RQcontainer img {
    height: 100px;
  }

  .RQFormControl {
    width: 65%;
  }
}
