.consultationContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.h2Cons {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 250px;
  background-color: #ee5a5a;
  font-size: 1.7rem;
}

.h2Cons div {
  text-align: center;
  width: 50%;
}

.consultationForm {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.consultationForm h3 {
  font-size: 1.4rem;
}

.consultationForm {
  margin-bottom: 10px;
  width: 50%;
}

.consultationForm form {
  color: black;
  border-radius: 0px;
}

.consultationForm form input {
  border-radius: 0px;
}

.consultationForm form label {
  font-size: 0.9rem;
}

.forms2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.form3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.movingFrom {
  margin-right: 10px;
}

.movingTo {
  margin-left: 10px;
}

@media (max-width: 800px) {
  .consBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .consultationForm form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .consBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
  }

  .consultationForm {
    width: 80%;
  }

  .consultationForm form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .forms2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .movingFrom {
    margin-right: 0px;
  }

  .movingTo {
    margin-left: 0px;
  }

  .h2Cons {
    font-size: 1.4rem;
  }

  .h2Cons div {
    text-align: center;
    width: 80%;
  }
}
