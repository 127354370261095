.locationsWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationsControl {
  display: flex;
  width: 90%;
  justify-content: center;
  flex-direction: row;
}

.lUlc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.locationsControl ul li {
  height: 300px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 50px;
  font-size: 2.5rem;
  -webkit-text-stroke: 0.7px black;
  transition: all 1s;
}

.locationsControl ul li a {
  color: white;
  text-decoration: none;
}

.lUlc li:hover {
  transform: scale(1.1);

  box-shadow: 10px 10px #888888;
}

.l1Box {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/vancouver.jpg");
  background-size: 100% 100%;
}

.l2Box {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/burnaby.jpg");
  background-size: 100% 100%;
}

.l3Box {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/coquitlam.jpg");
  background-size: 100% 100%;
}

.l4Box {
  background: linear-gradient(rgba(238, 90, 90, 0.5), rgba(238, 90, 90, 0.3)),
    url("img/newwestminster.jpg");
  background-size: 100% 100%;
}

@media (max-width: 800px) {
  .locationsControl ul li {
    height: 200px;
    width: 350px;
  }
}

@media (max-width: 600px) {
  .locationsControl ul li {
    height: 150px;
    width: 250px;
    font-size: 2rem;
    margin: 30px 0px;
  }

  .lUlc {
    padding-left: 0;
  }
}
