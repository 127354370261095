.containerIntro {
  display: flex;
  width: 100%;
  height: 800px;
  border-bottom: 5px solid #ffb174;
}

.background {
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.introText {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  height: 500px;
  align-items: center;
}

.introText h1 {
  color: white;
  font-size: 5rem;
  font-weight: 100;
  margin-bottom: 10px;
  text-align: center;
  margin: 0 200px;
}

.introText h2 {
  color: white;
  font-weight: 100;
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.buttonIntro {
  display: flex;
  justify-content: center;
}

.buttonIntro a {
  text-decoration: none;
  color: white;
  margin: 4rem 2rem;
}

#firstAI {
  padding: 10px 10px;
  transition: ease 0.3s;
  border-radius: 0px;
  border: 1px solid #fff;
  color: #fff;
  width: 200px;
  position: relative;
  z-index: 80;
}
#firstAI:after {
  transition: ease 0.3s;
  content: "";
  width: 100%;
  position: absolute;
  height: 0px;
  background-color: #ffb174;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
}
#firstAI:hover {
  color: white;
}
#firstAI:hover:after {
  height: 100%;
}

#firstAI {
  text-align: center;
  padding: 10px 10px;
  transition: ease 0.3s;
  border-radius: 0px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1.3rem;
  width: 200px;
  position: relative;
  z-index: 80;
}

#secondAI {
  text-align: center;
  padding: 10px 10px;
  transition: ease 0.3s;
  border-radius: 0px;
  border: 1px solid #ffb174;
  font-size: 1.3rem;
  color: #fff;
  width: 200px;
  position: relative;
  z-index: 80;
}

#secondAI:after {
  transition: ease 0.3s;
  content: "";
  width: 100%;
  position: absolute;
  height: 0px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
}
#secondAI:hover {
  color: #ffb174;
}
#secondAI:hover:after {
  height: 100%;
}

@media (max-width: 1200px) {
  .introText h1 {
    font-size: 3rem;
    text-align: center;
    margin: 0 0;
  }
}

@media (max-width: 800px) {
  .introText h1 {
    font-size: 3rem;
    text-align: center;
    margin: 0 0;
  }

  .introText h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .buttonIntro a {
    margin: 4rem 1rem;
    font-size: 1rem;
  }

  .background {
    background-position: center;
  }
}

@media (max-width: 600px) {
  .introText h1 {
    font-size: 3rem;
    text-align: center;
    margin: 0 0;
  }

  .introText h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .buttonIntro a {
    margin: 4rem 1rem;
  }

  .background {
    background-position: center;
  }

  #firstAI {
    font-size: 0.75rem;
    text-align: center;
  }

  #secondAI {
    font-size: 0.75rem;
    text-align: center;
  }
}
