.sideMenu {
  height: 100%;

  background-color: #ee5a5a;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.container {
  display: flex;
  justify-content: center;
}

.container button {
  background: none;
  border: 0px;
  margin-right: 2.5rem;
  cursor: pointer;
}

.logo {
  display: flex;
}

.sideMenu ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sideMenu li {
  margin: 1rem 3rem;
}

.sideMenu a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.sideMenu.open {
  transform: translateX(0);
}

@media (max-width: 600px) {
  .sideMenu {
    overflow-y: auto;
  }

  .logo img {
    height: 45px;
  }

  .sideMenu li {
    margin: 1rem 0.5rem;
  }

  .container button {
    margin-right: 0.5rem;
  }

  .container button img {
    height: 20px;
  }

  .sideMenu a {
    font-size: 1rem;
  }
}
