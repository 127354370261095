.CFBC {
  display: flex;
  width: 100%;
  justify-content: center;
}

.CFB {
  width: 40%;
  text-align: center;
  color: black;
}

.CFB h2 {
  font-size: 2rem;
}

.CFB p {
  font-size: 1.4rem;
}

.CSBCC {
  display: flex;
  width: 100%;
  justify-content: center;
}

.CSBC {
  color: black;
  display: flex;
  width: 50%;
}

.CSBCT {
  margin-right: 20px;
}

.CSBCT h3 {
  font-size: 1.7rem;
}

.CSBCI {
  margin-left: 20px;
}

.CTBCC {
  display: flex;
  width: 100%;
  justify-content: center;
}

.CTBC {
  color: black;
  display: flex;
  width: 50%;
  flex-direction: row-reverse;
}

.CTBCT {
}

.CTBCT h3 {
  font-size: 1.7rem;
}

.CTBCI {
  margin-right: 20px;
  margin-top: 35px;
}

@media (max-width: 1750px) {
  .CSBC {
    width: 65%;
  }
  .CTBC {
    width: 65%;
  }
}

@media (max-width: 800px) {
  .CFB {
    width: 80%;
  }

  .CFB h2 {
    font-size: 1.7rem;
  }

  .CFB p {
    font-size: 1.2rem;
  }

  .CSBC {
    width: 90%;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .CSBCT {
    margin-right: 20px;
  }

  .CSBCT h3 {
    font-size: 1.7rem;
  }

  .CSBCI {
    margin-left: 20px;
  }

  .CSBCI img {
    width: 90% !important;
  }

  .CTBC {
    width: 90%;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .CTBCT h3 {
    font-size: 1.7rem;
  }

  .CTBCI {
    margin-top: 35px;
  }

  .CTBCI img {
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .CFB {
    width: 80%;
  }

  .CFB h2 {
    font-size: 1.7rem;
  }

  .CFB p {
    font-size: 1.2rem;
  }

  .CSBC {
    width: 90%;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .CSBCT {
    margin-right: 20px;
  }

  .CSBCT h3 {
    font-size: 1.7rem;
  }

  .CSBCI {
    margin-left: 20px;
  }

  .CSBCI img {
    width: 90%;
  }

  .CTBC {
    width: 90%;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .CTBCT h3 {
    font-size: 1.7rem;
  }

  .CTBCI {
    margin-top: 35px;
  }

  .CTBCI img {
    width: 90%;
  }
}
