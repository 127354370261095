@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-image {
  width: 900px;
  height: 600px;
}

.galleryCn {
  display: flex;
  justify-content: center;
}

.gallery {
  display: flex;
  justify-content: center;
  width: 80%;
  height: 70%;
}
.headerGallery {
  display: flex;
  background-color: #ee5a5a;
  height: 25vh;
  align-items: center;
  justify-content: center;
}

.textGalleryController h2 {
  font-size: 5rem;
}

.galleryImageControl {
  margin: 10px 0px;
  margin-bottom: -300px;
  display: flex;
  justify-content: center;
}

.gridController {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 3px;
  grid-row-gap: 3px;
}

.gridController img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 700px) {
  .galleryImageControl {
    margin: 10px 0px;
    margin-bottom: 0px;
  }

  .gridController {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);

    justify-items: center;
  }

  .gridController img {
    width: 80%;
    height: 80%;
    margin: 10px 10px;
  }

  .headerGallery {
    align-items: flex-end;
    justify-content: center;
  }

  .textGalleryController h2 {
    font-size: 4rem;
    margin: 20px 0px;
  }
}

@media (max-width: 1500px) {
  .galleryImageControl {
    margin: 10px 0px;
    margin-bottom: -200px;
  }
}

@media (max-width: 800px) {
  .galleryImageControl {
    margin: 10px 0px;
    margin-bottom: 0px;
  }

  .gridController {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    justify-items: center;
  }

  .gridController img {
    width: 80%;
    height: 80%;
    margin: 10px 10px;
  }

  .headerGallery {
    align-items: flex-end;
    justify-content: center;
    height: 20vh;
  }

  .textGalleryController h2 {
    font-size: 4rem;
    margin: 20px 0px;
  }
}

@media (max-width: 500px) {
  .gridController img {
    width: 100%;
    height: 100%;
    margin: 10px 10px;
  }

  .headerGallery {
    align-items: flex-end;
    justify-content: center;
    height: 20vh;
  }

  .textGalleryController h2 {
    font-size: 3rem;
    margin: 10px 0px;
  }
}
