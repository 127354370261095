.Rwrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 200px;
}

.dix {
  display: flex;
  justify-content: center;
  align-items: center;
}

.x0 {
  display: flex;
}

.Rtext {
  font-size: 1.7rem;
  width: 50%;
}

.Rtext p {
  margin-bottom: 5px;
}

.Rtext #span {
  font-size: 1.3rem;
  float: right;
  margin-bottom: 20px;
  padding-right: 20px;
}

#span .x1 {
  white-space: nowrap;
}

/* .Rtext p:before {
  content: "";
  -webkit-animation: animateP infinite 13s;
  -o-animation: animateP infinite 13s;
  animation: animateP infinite 13s;
}

.Rtext #span:before {
  content: "";
  -webkit-animation: animateS infinite 13s;
  -o-animation: animateS infinite 13s;
  animation: animateS infinite 13s;
}

@keyframes animateP {
  0% {
    content: "Roadway showed up right when they said they would, and worked hard, never slowing up. Thanks for everything!";
  }
  20% {
    content: "Nothing was damaged and they went above and beyond. I can’t recommend these guys enough.";
  }
  40% {
    content: "Thank you Roadway for our move to NYC. They were a great group of guys that did a perfect job of packing and unpacking.";
  }
  60% {
    content: "Our double move was extremely complicated, but it was almost stress free due to Roadway's outstanding service.";
  }
  80% {
    content: "It's hard to find a reliable source to manage and handle your most prized possessions. I'm very grateful to Roadway!";
  }
  100% {
    content: "I will definitely use them next time I move. All in all, a great experience.";
  }
}

@keyframes animateS {
  0% {
    content: "-Alexander R.";
  }
  20% {
    content: "-Miriam R.";
  }
  40% {
    content: "-Lindsey L.";
  }
  60% {
    content: "-Cecil H.";
  }
  80% {
    content: "-Bruce B.";
  }
  100% {
    content: "-Yessenia R.";
  }
} */

@media (max-width: 800px) {
  .Rwrap {
    margin: 0px 0px;
    text-align: center;
  }

  .Rtext {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .Rwrap {
    text-align: center;
    margin: 0px 0px;
  }

  .Rtext {
    width: 80%;
  }
}
