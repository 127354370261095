.mainPageContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.mpBackground {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("bg.png");
  width: 100%;
  height: 100%;
}

.mainPageForm {
  margin-right: 20px;
}

.mpTextWr {
  color: white;
  display: flex;
  width: 40%;
  flex-direction: column;
}

/* center img */
.mainImg {
  text-align: center;
}

.mainImg img {
  height: 100px;
}

.mpTextWr h3 {
  font-size: 3rem;
  margin-bottom: 0;
  text-align: left;
}

.mpTextWr p {
  font-size: 1.5rem;
}

@media (max-width: 1600px) {
}

@media (max-width: 800px) {
  .mpBackground {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .mainPageContainer {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }

  .mpTextWr {
    width: 80%;
    flex-direction: column;
  }

  .mpBackground {
    height: 900px;
  }

  .mainImg img {
    height: 70px;
  }
}
